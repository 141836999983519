export default {
  account: {
    confirmPassword: "Confirmation du mot de passe",
    currentPassword: "Mot de passe actuel",
    newPassword: "Nouveau mot de passe",
    save: "Sauvegarder",
    title: "Mon compte",
  },
  attributes: {
    administrator: {
      active: "Compte actif",
      email: "Adresse courriel",
      firstName: "Prénom",
      lastName: "Nom",
    },

    defaults: {
      createdAt: "Date de création",
      id: "Identifiant",
      name: "Nom",
      nameEn: "Nom (EN)",
      nameFr: "Nom (FR)",
      updatedAt: "Date de modification",
    },
    productInfo: {
      longDescription: "Description longue",
      name: "Nom de la variante",
      shortDescription: "Description courte",
    },
    productVariant: {
      manufacturerSku: "SKU du fournisseur",
      price: "Prix",
      salePrice: "Rabais",
    },
    productVariantQuantity: {
      quantity: "Quantité",
    },
    user: {
      email: "Adresse courriel",
      firstName: "Prénom",
      lastName: "Nom",
      password: "Mot de passe",
    },
  },
  models: {
    administrator: {
      one: "Administrateur",
      other: "Administrateurs",
    },
    keyword: {
      one: "Balise",
      other: "Balises",
    },
    manufacturer: {
      one: "Fournisseur",
      other: "Fournisseurs",
    },
    product: {
      one: "Produit",
      other: "Produits",
    },
    productVariant: {
      one: "Variante de produit",
      other: "Variantes de produits",
    },
    tag: {
      one: "Balise",
      other: "Balises",
    },
  },
};
